<template>
  <div class="insights-number-container">
    <p>المنتج الأكثر مشاهدة هذا الأسبوع</p>
    <div class="insights-data">
      <div class="insights-image">
        <img :src="item.image" alt="" />
      </div>
      <div class="insights-info">
        <p class="insights-name">
          {{ item.name }}
        </p>
        <p class="insights-number">
          {{ item.stats }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"]
};
</script>

<style scoped lang="scss">
.insights-number-container {
  margin: 10px;
  flex: 1 1;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 10px;
  min-width: 300px;
  > p {
    height: 30px;
    direction: rtl;
  }
  .insights-data {
    flex: 1 1;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    height: 100px;
    padding: 10px;
    min-width: 300px;
    height: 100px;
    .insights-image {
      // flex: 1 2;
      margin-right: 20px;
      img {
        height: 80px;
        width: 100px;
        object-fit: scale-down;
      }
    }
    .insights-info {
      flex: 2 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      p.insights-number {
        min-width: 120px;
        font-weight: bold;
        font-size: 30px;
      }
      p.insights-name {
        min-width: 120px;
        font-size: 30px;
      }
    }
  }
}
</style>
