import moment from "moment";

const chartOptions = {
  xaxis: {
    type: "datetime"
  },
  stroke: {
    curve: "straight"
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
    style: "hollow"
  },
  tooltip: {
    x: {
      format: "d MM yyyy"
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  }
};

export default function insights(reads = [], dateFilter, dateValue) {
  if (reads === [] || dateFilter == null) {
    return null;
  }
  const dataPoints = generateDataPoints(reads, dateFilter, dateValue);
  changeMInMaxForXaxis(dateFilter, dateValue);
  return { dataPoints, chartOptions };
}

function generateDataPoints(reads, dateFilter, dateValue) {
  const currentDate = moment();
  return dateFilter === "month"
    ? Object.keys(reads).reduce((acc, dayNumber) => {
        if (!isNaN(parseInt(dayNumber))) {
          acc.push([
            moment([currentDate.year(), dateValue, dayNumber]).valueOf(),
            reads[dayNumber].count
          ]);
        }
        return acc;
      }, [])
    : dateFilter === "day"
    ? Object.keys(reads).reduce((acc, hourNumber) => {
        if (!isNaN(parseInt(hourNumber))) {
          acc.push([
            moment(dateValue)
              .hour(hourNumber - 1)
              .valueOf(),
            reads[hourNumber].count
          ]);
        }
        return acc;
      }, [])
    : Object.keys(reads).reduce((acc, monthNumber) => {
        if (!isNaN(parseInt(monthNumber))) {
          acc.push([moment([currentDate.year(), monthNumber]).valueOf(), reads[monthNumber].count]);
        }
        return acc;
      }, []);
}

function changeMInMaxForXaxis(dateFilter, dateValue) {
  const currentDate = moment();
  if (dateFilter === "month") {
    const startOfTheMonth = moment([currentDate.year(), dateValue]);
    chartOptions.xaxis = {
      type: "datetime",
      min: startOfTheMonth.valueOf(),
      max: startOfTheMonth.endOf("month").valueOf()
    };
  } else if (dateFilter === "day") {
    const DayOfTheMonth = moment(dateValue);
    chartOptions.xaxis = {
      type: "datetime",
      min: DayOfTheMonth.valueOf(),
      max: DayOfTheMonth.endOf("day").valueOf()
    };
  } else if (dateFilter === "year") {
    const DayOfTheMonth = moment([dateValue]);
    chartOptions.xaxis = {
      type: "datetime",
      min: DayOfTheMonth.valueOf(),
      max: DayOfTheMonth.endOf("year").valueOf()
    };
  }
}
