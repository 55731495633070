<template>
  <div style="postion: relative; margin-bottom: 100px;">
    <div v-if="!loading">
      <div v-if="mostViewedItem != null" class="quick-insights">
        <insightCard :item="mostViewedItem"></insightCard>
        <!-- <insightCard></insightCard> -->
      </div>

      <p
        style="height: 100px;height: 100px;
          place-content: center;
          align-items: center;
          justify-content: center;
          align-content: center;
          font-weight: bold;
          font-size: 22px;
          display: flex;"
      >
        {{ $store.getters["page/botRecipe"] === "store" ? "بيانات المنتجات" : "بيانات الخدمات" }}
      </p>

      <dropdownMenu
        :options="items"
        :placeHolder="$store.getters['page/botRecipe'] === 'store' ? 'اختر منتجاً' : 'اختر خدمة'"
        v-model="selectedElement"
      >
      </dropdownMenu>

      <insightsGraphs
        insightsSection="items"
        :title="$store.getters['page/botRecipe'] === 'store' ? 'بيانات المنتجات' : 'بيانات الخدمات'"
        :elementId="selectedElement ? selectedElement.id : ''"
      ></insightsGraphs>

      <hr />

      <p
        style="height: 100px;height: 100px;
          place-content: center;
          align-items: center;
          justify-content: center;
          align-content: center;
          font-weight: bold;
          font-size: 22px;
          display: flex;"
      >
        بيانات الفئات
      </p>

      <dropdownMenu
        :options="categories"
        placeHolder="اختر فئة"
        v-model="selectedCategory"
        expanded
      >
        <template v-slot:trigger="slotProps">
          <font-awesome-icon icon="tag" />
          <p>{{ slotProps.selected.name }}</p>
        </template>
        <template v-slot:option="slotProps">
          <font-awesome-icon icon="tag" />
          <p>{{ slotProps.option.name }}</p>
        </template>
      </dropdownMenu>

      <insightsGraphs
        insightsSection="categories"
        title="بيانات الفئات"
        :elementId="selectedCategory ? selectedCategory.id : ''"
      ></insightsGraphs>
    </div>
    <loading-spinner position="absolute" v-else></loading-spinner>
  </div>
</template>

<script>
/* eslint-disable */
import dropdownMenu from "./../../input/DropDownMenu"
import insightsGraphs from './insights-graph'
import insightCard from './insightsCard'
export default {
  name: 'insights',
  components: {
    dropdownMenu,
    insightsGraphs,
    insightCard
  },
  data () {
    return {
      items: null,
      categories: null,
      loading: false,
      selectedElement: null,
      selectedCategory: null,
      mostViewedItem: null
    }
  },
  created () {
    this.loading = true
    const promises = [
      this.$api.customRequest({
        method: 'get',
        url: `/services/chatbot/pages/${this.$store.getters['page/id']}/users/${this.$store.getters['user/id']}/insights/items`,
        
        responseType: 'json'
      }),
      this.$api.customRequest({
        method: 'get',
        url: `/services/chatbot/pages/${this.$store.getters['page/id']}/users/${this.$store.getters['user/id']}/insights/items/most-viewed`,
        
        responseType: 'json'
      }),
      this.$api.customRequest({
        method: 'get',
        url: `/services/chatbot/pages/${this.$store.getters['page/id']}/users/${this.$store.getters['user/id']}/insights/categories`,
        
        responseType: 'json'
      })
    ]
    Promise.all(promises)
      .then(([itemsRes, mostViewedItemRes, categoriesRes]) => {
        this.items = itemsRes.data.items
        this.categories = categoriesRes.data.categories
        if (mostViewedItemRes.data.success && mostViewedItemRes.data.success)
        this.mostViewedItem = mostViewedItemRes.data.item
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped lang="scss">
.quick-insights {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.m-field-label {
  label {
    font-size: 18px;
  }
}
</style>
