<template>
  <div class="m-dropdown-menu" :id="`drop_down_trigger_${id}`" v-if="period === 'day'">
    <div
      class="trigger-container__trigger rtl"
      :class="{ expanded: expanded }"
      @click="onTriggerClicked"
    >
      <p>{{ selectedOption ? parsedDate : placeHolder }}</p>
    </div>
    <v-date-picker
      v-if="showOptions"
      @input="onOptionSelected"
      :id="`DATE_PICKER_${id}`"
      is-expanded
      is-inline
      :theme-styles="datePickerOptions.themeStyles"
      :select-attribute="datePickerOptions.select_attribute"
      v-model="selectedOption"
      :popover-expanded="true"
    >
    </v-date-picker>
  </div>

  <div v-else class="m-dropdown-menu" :id="`drop_down_trigger_${id}`">
    <div
      class="trigger-container__trigger rtl"
      :class="{ expanded: expanded }"
      @click="onTriggerClicked"
    >
      <p>{{ selectedOption ? selectedOption : placeHolder }}</p>
      <ul
        class="options"
        :id="`m-dropdown-menu-options_${id}`"
        :class="{ show: showOptions, expanded: expanded }"
      >
        <li
          class="option"
          v-for="option in options"
          :key="option.id"
          @click="onOptionSelected(option)"
        >
          <slot name="option" v-bind:option="option">
            {{ option }}
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    period: {
      type: String,
      default: "month"
    },
    expanded: Boolean,
    placeHolder: {
      type: String,
      default: "سنة"
    },
    id: {
      type: String,
      default: function() {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
    },
    value: {
      type: [Object, String, Number, Date]
    }
  },
  data() {
    const hSpacing = "15px";
    return {
      showOptions: false,
      selectedOption: null,
      datePickerOptions: {
        themeStyles: {
          wrapper: {
            background: "white",
            color: "#fafafa",
            "border-radius": "6px",
            border: "1px solid dodgerblue",
            position: "absolute",
            "z-index": "15",
            top: "110%",
            left: "50%",
            transform: "translateX(-50%)"
          },
          header: {
            padding: `20px ${hSpacing}`,
            color: "dodgerblue",
            "font-weight": "bold"
          },
          headerHorizontalDivider: {
            borderTop: "solid rgba(57, 57, 57, 0.2) 1px",
            width: "80%"
          },
          weekdays: {
            color: "black", // New color
            fontWeight: "700", // And bolder font weight
            padding: `20px ${hSpacing} 5px ${hSpacing}`
          },
          weeks: {
            padding: `0 ${hSpacing} ${hSpacing} ${hSpacing}`
          },
          dayContent: {
            color: "black",
            fontWeight: "600"
          },
          dayCellNotInMonth: {
            opacity: 0
          }
        },
        select_attribute: {
          highlight: {
            backgroundColor: "#007afe"
          }
        }
      },
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    };
  },
  methods: {
    onOptionSelected(option) {
      this.selectedOption = option;
      if (this.period === "month") {
        this.$emit("input", this.monthIndex);
      } else {
        this.$emit("input", option);
      }
      this.showOptions = false;
    },
    onTriggerClicked() {
      this.showOptions = !this.showOptions;
    },
    isVisible(elem) {
      const res =
        !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      return res;
    },
    clickOutSideElement(element, target) {
      return this.isVisible(element) && !element.contains(event.target);
    },
    outsideClickListener_date(event) {
      const trigger = document.getElementById(`drop_down_trigger_${this.id}`);
      if (trigger) {
        if (!trigger.contains(event.target)) {
          this.showOptions = false;
        }
        if (event.target.matches(".option")) {
          this.showOptions = false;
        }
      }
    }
  },
  computed: {
    options() {
      if (this.period === "month") {
        return this.months;
      }
      if (this.period === "year") {
        return this.years;
      }
      return "";
    },
    years() {
      const years = [];
      for (let year = 2019; year < 2030; year++) {
        years.push(year);
      }
      return years;
    },
    parsedDate() {
      if (this.period === "day") {
        return moment(this.selectedOption).format("D / M / YYYY");
      }
      return "";
    },
    monthIndex() {
      if (this.period === "month") {
        return this.months.findIndex(month => month === this.selectedOption);
      }
      return 1;
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (this.period === "month") {
        this.selectedOption = this.months[newVal];
      } else {
        this.selectedOption = newVal;
      }
    }
  },
  created() {
    this.selectedOption = this.value;
  },
  mounted() {
    document.addEventListener("click", this.outsideClickListener_date);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.outsideClickListener_date);
  }
};
</script>

<style scoped lang="scss">
.m-dropdown-menu {
  width: 100%;
  &.selected {
    background: dodgerblue !important;
    border: none;
    .trigger-container__trigger {
      p {
        color: white !important;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
    }
  }
  .trigger-container__trigger {
    padding: 10px;
    background: inherit;
    display: flex;
    border-radius: inherit;
    color: dodgerblue;
    height: 50px;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    // direction: ltr;
    text-align: right;
    border-radius: inherit;
    p {
      font-weight: bold;
      color: dodgerblue;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
    &.item-selected {
      min-width: 120px;
      display: flex;
      background: white;
      // border: 1px solid rgba(168, 168, 168, 0.295);
      align-content: center;
      align-items: center;
      justify-content: center;
      height: 50px;
      padding: 10px;
      cursor: pointer;
      p {
        flex: 1 1 auto;
        margin-left: 10px;
        color: dodgerblue;
      }
    }
  }
  .options {
    display: none;
    margin-top: 5px;
    z-index: 15;
    direction: ltr;
    font-weight: bold;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    &.show {
      display: inline-flex;
      flex-direction: column;
      align-content: center;
      align-items: flex-start;
      border-radius: 6px;
      background: white;
      // max-width: 500px;
      // min-width: 200px;
      max-height: 400px;
      overflow-y: auto;
      // box-shadow: 0 4px 6px -1px #409eff6b, 0 2px 4px -1px #409eff3d;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.expanded {
      width: 100%;
    }
    .option {
      height: 50px;
      display: flex;
      width: 100%;
      position: relative;
      align-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      color: dodgerblue;
      justify-content: flex-end;
      i {
        color: dodgerblue;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 95%;
        height: 1px;
        background: rgba(168, 168, 168, 0.11);
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &:hover {
        background: rgba(168, 168, 168, 0.295);
      }
      .option__image {
        height: 100%;
        img {
          margin: auto;
          height: 95%;
          width: 60px;
          object-fit: scale-down;
        }
      }
      p {
        flex: 1 1 auto;
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
</style>
