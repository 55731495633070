<template>
  <div class="bot-initialization-dialog relative">
    <loading-spinner position="absolute" v-if="loading"></loading-spinner>
    <div class="progress-bar" ref="m-progress-bar"></div>
    <div>
      <div
        class="wizard-container"
        v-for="(section, index) in wizardSections"
        :key="index"
        v-if="step === index"
      >
        <p id="chatbot-sectionTitle" style="font-size: 25px;">
          {{ index + 1 }} - {{ section.title }}
        </p>
        <div
          v-if="section.image"
          class="flex justify-center items-center content-center w-full"
          style="height: 300px;"
        >
          <img :src="section.image" style="height: 100%; object-fit: scale-down" />
        </div>
        <div>
          <p class="section-description">{{ section.body }}</p>
          <ul v-if="section.list" class="p-4">
            <li v-for="(item, index) in section.list" :key="index" style="list-style-type: disc;">
              <p v-if="item.title" class="font-bold text-lg">{{ item.title }}</p>
              <ul v-if="item.sub_list" class="pr-6">
                <li v-for="(sub_item, sub_index) in item.sub_list" :key="sub_index">
                  <p class="leading-loose" v-html="sub_item"></p>
                </li>
              </ul>
              <p v-else v-html="item" class="leading-loose text-lg"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="wizard-control">
      <button class="mujeeb-button" @click="previousSection" v-if="step >= 1">رجوع</button>
      <button class="mujeeb-button green" @click="nextSection" v-if="step < wizardSections.length">
        {{ step === wizardSections.length - 1 ? "تفعيل" : "التالي" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 0,
      loading: false,
      wizardSections: [
        {
          title: "عن خدمة التوصيل",
          image:
            "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/3lharek.png",
          body: `خدمة عالحارك , هي خدمة توصيل ونقل داخلي للأغراض اللوجستية ,
                باستخدام الدراجات ( هوائية – كهربائية – نارية ) تعتمد على وجودها
                كطرف ثالث بين المرسل والمستلم دون التدخل بنوعية أو ما هية
                الغرض المرسل أو قيمته .`
        },
        {
          title: "أوقات العمل",
          body: `كل أيام الأسبوع عدا الجمعة، من الساعة ٩ صباحاً حتى ٩ مساءً.`
        },
        {
          title: "الامتداد الجغرافي",
          image:
            "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/map@3x.png",
          body: `ضمن مدينة دمشق:`,
          list: [
            {
              title: "مناطق الفئة A:",
              sub_list: [
                `<b>المهاجرين :</b> شورى – المصطبة – المرابط – الروضة – أبو رمانة – المالكي – الحبوبي - جادات " لحد الجادة الثانية "`,

                `<b>الصالحية :</b> الشيخ محي الدين – المدارس – المزرعة – الشهداء - أبو جرش`,
                `<b>ركن الدين :</b> الميسات – شمدين – ابو النور – الفيحاء – برنية – ابن العميد – أسد الدين`,
                `<b>المزة :</b> شيخ سعد – الجلاء – مزة جبل لغاية ساحة الهدى – فيلات شرقية – فيلات غربية – مزة ٨٦ " عند الحاجز "
                  مزة بساتين خلف الرازي`,
                `<b>كفرسوسة :</b> تنظيم كفرسوسة – كفرسوسة البلد – الفردوس`,
                `<b>الشاغور :</b> شاغور براني – باب شرقي – ابن عساكر – الصناعة – الوحدة – بلال – مسبق الصنع
                حي الزهور عند الحديقة – التضامن عند الفرن الآلي`,
                `<b>الميدان :</b> كورنيش – ابو حبل – القاعة – الحقلة – الدقاق – باب مصلى – الزاهرة القديمة – الزاهرة الجديدة`,
                `<b>القنوات :</b> القنوات – الحجاز – البرامكة – باب الجابية – باب سريجة – السويقة – قبر عاتكة – المجتهد – الأنصاري`,
                `<b>ساروجة :</b> سوق ساروجة – العقيبة – العمارة البرانية – مسجد الأقصاب – القصاع – القصور – شارع بغداد
                العدوي - فارس خوري`,
                `<b>دمشق القديمة :</b> الجورة – العمارة الجوانية – باب توما – القيمرية – الحميدية – الحريقة – الأمين
                مأذنة الشحم – شاغور جواني`,
                `<b>الجسر الأبيض – الطلياني – السبع بحرات – عين كرش - شارع ٢٩ أيار – فكتوريا – جسر الرئيس</b>`
              ]
            },
            {
              title: "مناطق الفئة B:",
              sub_list: [
                `<b>برزة :</b> لغاية مشفى حاميش والبنك التجاري - كنيسة
                جامع القزاز - جامع الرحمة - نادي قاسيون قاسيون مول
                مساكن برزة`,
                `<b>مشروع دمر :</b> كاملًا`,
                `<b>دمر البلد :</b> لغاية حديقة دمر البلد`
              ]
            },
            {
              title: "مناطق الفئة C:",
              sub_list: [`<b>ضاحية قدسيا عدا السكن الشبابي</b>`, `<b>قدسيا البلد</b>`]
            }
          ]
        },
        {
          title: "آلية العمل",
          body: "الأجور",
          list: [
            `<p>مناطق الفئة &#8207; <strong>A</strong>&#8207;:  &nbsp;&nbsp; 500 ليرة سورية</p>`,
            `<p>&#8207;مناطق الفئة <strong>A</strong>&#8207;  مع إعادة ثمن السلعة مباشرة للبائع:  &nbsp;&nbsp; <strong>750</strong> ليرة سورية</p>`,
            `<p>&#8207;مناطق الفئة <strong>B</strong>&#8207;: &nbsp;&nbsp; <strong>800</strong> ليرة سورية</p>`,
            `<p>&#8207;مناطق الفئة <strong>B</strong>&#8207;  مع إعادة ثمن السلعة مباشرة للبائع:  &nbsp;&nbsp; <strong>1000</strong> ليرة سورية</p>`,
            `<p>&#8207;مناطق الفئة <strong>C</strong>&#8207;: &nbsp;&nbsp; <strong>1000</strong> ليرة سورية</p>`,
            `<p>&#8207;مناطق الفئة <strong>C</strong>&#8207;  مع إعادة ثمن السلعة مباشرة للبائع:  &nbsp;&nbsp; <strong>1200</strong> ليرة سورية</p>`
          ]
        },
        {
          title: "الزمن المقدر لكل عملية",
          list: [
            `مناطق الفئة <b>A</b> خلال مدة تتراوح بين <b>ساعتين إلى 24 ساعة من وقت الطلب.</b>`,
            `مناطق الفئة <b>B</b> خلال مدة تتراوح بين<b> 12 ساعة إلى 36 ساعة من وقت الطلب.</b>`,
            `مناطق الفئة <b>C</b> خلال مدة تتراوح بين<b> 12 ساعة و 48 ساعة من وقت الطلب.</b>`
          ]
        },
        {
          title: "أنواع الأغراض التي ننقلها",
          list: [
            `<p><strong>كامل الأغراض اللوجستية</strong> الممكن نقلها باستخدام دراجات ( هوائية &ndash; كهربائية &ndash; نارية ) <strong>لا يزيد</strong> وزنها عن <strong>١٥ كغ</strong>.</p>`,
            `<p>المنتجات والأغراض التي يتم بيعها من قبل محال تجارية أو على الإنترنت.</p>`,
            `<p><strong>لا نقوم</strong> بخدمة التوصيل للطعام والمأكولات من المطاعم.</p>`,
            `<p><strong>لا نقوم</strong> بخدمة التوصيل <strong>للأموال فوق</strong> مبلغ <em><strong>200000</strong></em> ليرة سورية ويتم استلامها بظرف <strong>مختوم حصرا</strong>.</p>`,
            `<p><strong>لا نقوم</strong> بخدمة التوصيل لأموال <strong>بغير</strong> العملة السورية.</p>`,
            `<p><strong>الأغراض الثمينة</strong> يتم حصراً استلامها بشكل طرد<strong> مختوم</strong> من قبل المرسل , <strong>ويتم إخبار الشركة بالمضمون حرصا على سلامته.</strong></p>`
          ]
        },
        {
          title: "آلية العمل",
          body: "تحصيل أثمان السلع والمنتجات",
          list: [
            `<p><strong>يتم تحصيل ثمن منتجات كافة طلبات اليوم &ndash; سعر المنتجات والبضائع &ndash;</strong><strong> من المستلمين والزبائن , ويتم تسليمهم للمتجر/الشركة في اليوم</strong><strong> الذي يليه صباحاً بالتنسيق مع المتجر/الشركة .</strong></p>`,
            `<p><strong>يتحمل كلفة التوصيل الزبون أو المتجر/الشركة حسب الاتفاق</strong><strong> المسبق , مع التأكيد على أخبار الزبون بكلفة التوصيل مسبقا</strong><strong> إذا كان هو من سيتحمل الكلفة .</strong></p>`
          ]
        },
        {
          title: "مسؤوليات وضمانات",
          list: [
            `تضمن شركة عالحارك سلامة كافة الطرود المرسلة بمجرد استلامها
              وتسليم الإيصال للمرسل.`,
            `تتحمل شركة عالحارك الحفاظ على سرية مضمون الطرود المستلمة.`,
            `لا نتحمل مسؤولية جودة الطرد أو المنتج المرسل من قبل المتجر.`,
            `لا نتحمل مسؤولية خطأ المتجر في حال إرسال طرد مغاير لطلب
            الزبون النهائي.`,
            `تضمن شركة عالحارك استلام ٨٠ ٪ كحد أدنى من الطلبات من قبلكم
            وأن لا يتم رفض اكثر من ٢٠ ٪ من الطلبات في حال الضغط.`,
            `لسنا مسؤولين عن الخطأ في البيانات المرسلة من قبل المتجر
            مثل : رقم أو اسم أو عنوان المستلم أو سعر السلعة.`,
            `لسنا مسؤولين عن مضمون الطرود المختومة اذا كانت مخالفة للقوانين , ويتحمل
            المرسل كامل مسؤوليتها امام الجهات الرسمية.`
          ]
        },
        {
          title: "المرتجع، التبديل، إلغاء الطلب",
          list: [
            `<p><strong>المرتجع:</strong><br />في حال وجود مرتجع يتم إعادته إلى المتجر في <strong>صباح اليوم التالي لعملية البيع</strong> ( مع ثمن الطلبات الأخرى إن وجد) <strong>ويتحمل البائع كلفة التوصيل</strong>.</p>`,
            `<p><strong>التبديل:</strong><br />في حال وجود طلب تبديل، يتم احتساب كلفته ككلفة <strong>طلبي توصيل</strong></p>`,
            `<p><strong>إلغاء الطلب:</strong><br />في حال <strong>ألغى الزبون الطلب</strong> عند تسليمه المنتج ( أي لم يرد استلام المنتج) <strong>يتحمل البائع كلفة التوصيل</strong> ويتم إعادة الطرد إليه في صباح اليوم التالي مع ثمن المنتجات الاخرى إن وجد</p>`,
            `<p>في حال عدم القدرة على الوصول إلى الزبون <strong>(عدم الرد على اتصالاتنا او انقطاع التواصل معه من قبله)</strong> وفي حال وصول المراسل إليه، <strong>يتحمل البائع كلفة التوصيل</strong> ويتم إعادة الطرد إليه في صباح اليوم الثاني مع ثمن المنتجات الأخرى إن وجد.</p>`
          ]
        }
      ]
    };
  },
  methods: {
    nextSection() {
      if (this.step === this.wizardSections.length - 1) {
        // TODO show the user a confirmation message
        this.$buefy.dialog.confirm({
          title: `تفعيل خدمة التوصيل`,
          message: `هل انت متأكد من رغبتك بتفعيل خدمة التوصيل؟`,
          confirmText: "متأكد",
          cancelText: "كلا",
          type: "is-success",
          onConfirm: () => {
            // TODO make request to add the recipe to this pages
            this.loading = true;
            return this.initDelivery("3lharek")
              .then(() => {
                return this.$store
                  .dispatch("page/initDelivery", {
                    provider: "3lharek",
                    enabled: true
                  })
                  .catch(error => {
                    throw { type: "store.dispatch", error: error };
                  });
              })
              .then(() => {
                try {
                  this.$router.push({
                    name: "chatbot.delivery.pending"
                  });
                } catch (error) {
                  throw { type: "router.push", error: error };
                }
              })
              .catch(errObject => {
                console.log(errObject);
                if (errObject.type != null) {
                  this.$buefy.toast.open({
                    duration: 3000,
                    message: "حدث خطأ ما، يرجت التواصل مع الفريق",
                    position: "is-top",
                    type: "is-danger"
                  });
                } else {
                  if (errObject.response.data) {
                    this.$buefy.toast.open({
                      duration: 3000,
                      message: errObject.response.data.message,
                      position: "is-top",
                      type: "is-danger"
                    });
                  } else {
                    this.$buefy.toast.open({
                      duration: 3000,
                      message: "حدث خطأ ما، يرجت التواصل مع الفريق",
                      position: "is-top",
                      type: "is-danger"
                    });
                  }
                }
                this.step = 0;
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      } else {
        const element = document.getElementById("chatbot-sectionTitle");
        element.scrollIntoView();
        this.step += 1;
        const ratio = ((this.step + 1) / this.wizardSections.length) * 100;
        this.$refs["m-progress-bar"].style.left = `${100 - ratio}%`;
      }
    },
    previousSection() {
      if (this.step > 0) {
        const element = document.getElementById("chatbot-sectionTitle");
        element.scrollIntoView();
        this.step -= 1;
        const ratio = ((this.step + 1) / this.wizardSections.length) * 100;
        this.$refs["m-progress-bar"].style.left = `${100 - ratio}%`;
      } else {
        this.$emit("back");
      }
    },
    initDelivery() {
      return this.$api
        .customRequest({
          method: "post",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/delivery`,
          responseType: "json"
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    }
  },
  mounted() {
    const ratio = (1 / this.wizardSections.length) * 100;
    this.$refs["m-progress-bar"].style.left = `${100 - ratio}%`;
  }
};
</script>

<style scoped lang="scss">
.bot-initialization-dialog {
  .wizard-container {
    .section-description {
      text-align: center;
      font-size: 23px;
      line-height: 50px;
    }
    > p {
      direction: rtl;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 30px auto;
    }
    > div {
      margin-bottom: 50px;
      padding: 10px;
      > p {
        direction: rtl;
        font-size: 18px;
      }
      ul {
        margin-top: 10px;
        direction: rtl;
        li {
          margin-top: 5px;
        }
      }
    }
  }
  .wizard-control {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 100px;
    flex-direction: row-reverse;
  }
  .progress-bar {
    position: fixed;
    height: 4px;
    background: $mujeeb-blue;
    top: 0px;
    right: 0%;
    left: 100%;
  }
}
</style>
