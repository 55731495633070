<template>
  <div class="m-dropdown-menu unselectable">
    <div
      :id="`m-dropmenu-trigger_${id}`"
      class="trigger-container__trigger rtl"
      :class="{ 'item-selected': selectedOption, expanded: expanded }"
      @click="onTriggerClicked"
    >
      <p v-if="!selectedOption">{{ placeHolder }}</p>
      <slot v-else name="trigger" v-bind:selected="selectedOption">
        <div class="selected-option__image">
          <img :src="selectedOption.image" alt="" />
        </div>
        <p>{{ selectedOption.name }}</p>
      </slot>
      <font-awesome-icon icon="sort-down" />
      <ul class="options" :class="{ show: showOptions }" :ref="`m-dropdown-menu-options_${id}`">
        <li
          class="option"
          v-for="option in options"
          :key="option.id"
          @click="onOptionSelected(option)"
        >
          <slot name="option" v-bind:option="option">
            <div class="option__image">
              <img :src="option.image" alt="" />
            </div>
            <p>{{ option.name }}</p>
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    placeHolder: {
      type: String,
      default: "اختر فئة"
    },
    value: [Array, Object, String, Number],
    expanded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedOption: null,
      showOptions: false
    };
  },
  methods: {
    onOptionSelected(option) {
      this.selectedOption = option;
      this.$emit("input", option);
      this.showOptions = false;
    },
    onTriggerClicked() {
      this.showOptions = !this.showOptions;
    },
    isVisible(elem) {
      const res =
        !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      return res;
    },
    outsideClickListener(event) {
      const options = this.$refs[`m-dropdown-menu-options_${this.id}`];
      const trigger = document.getElementById(`m-dropmenu-trigger_${this.id}`);
      if (
        this.isVisible(trigger) &&
        !trigger.contains(event.target) &&
        !options.contains(event.target) &&
        this.isVisible(options)
      ) {
        this.showOptions = false;
      } else {
        if (options.contains(event.target) && this.isVisible(options)) {
          this.showOptions = false;
        }
      }
    }
  },
  computed: {
    id() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  },
  watch: {
    value(newVal, oldVal) {
      this.selectedOption = newVal;
    }
  },
  mounted() {
    document.addEventListener("click", this.outsideClickListener);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.outsideClickListener);
  }
};
</script>

<style scoped lang="scss">
.m-dropdown-menu {
  .trigger-container__trigger {
    padding: 10px;
    background: white;
    display: inline-flex;
    border-radius: 6px;
    color: dodgerblue;
    height: 50px;
    position: relative;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    align-content: center;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: white;
    // direction: ltr;
    text-align: right;
    p {
      font-weight: bold;
      color: dodgerblue;
      margin-left: 20px;
      font-size: 16px;
    }
    &.item-selected {
      min-width: 120px;
      display: inline-flex;
      border-radius: 6px;
      background: white;
      // border: 1px solid rgba(168, 168, 168, 0.295);
      position: relative;
      align-content: center;
      align-items: center;
      justify-content: center;
      height: 50px;
      padding: 10px;
      cursor: pointer;
      .selected-option__image {
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        img {
          margin: auto;
          height: 95%;
          width: 60px;
          object-fit: scale-down;
        }
      }
      p {
        flex: 1 1 auto;
        margin-left: 10px;
        color: dodgerblue;
      }
      i {
        color: dodgerblue;
      }
    }
    &.expanded {
      width: 100%;
    }
    i {
      color: dodgerblue;
      line-height: 25px;
      font-size: 20px;
    }
  }

  .options {
    display: none;
    margin-top: 5px;
    z-index: 15;
    direction: ltr;
    font-weight: bold;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    &.show {
      display: inline-flex;
      flex-direction: column;
      align-content: center;
      align-items: flex-start;
      border-radius: 6px;
      background: white;
      // max-width: 500px;
      width: 100%;
      min-width: 200px;
      max-height: 400px;
      overflow-y: auto;
      // box-shadow: 0 4px 6px -1px #409eff6b, 0 2px 4px -1px #409eff3d;
      position: absolute;
      top: 100%;
      right: 0px;
    }
    .option {
      height: 50px;
      display: flex;
      width: 100%;
      position: relative;
      align-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      color: dodgerblue;
      i {
        color: dodgerblue;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 95%;
        height: 1px;
        background: rgba(168, 168, 168, 0.11);
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &:hover {
        background: rgba(168, 168, 168, 0.295);
      }
      .option__image {
        height: 100%;
        img {
          margin: auto;
          height: 95%;
          width: 60px;
          object-fit: scale-down;
        }
      }
      p {
        flex: 1 1 auto;
        margin-left: 10px;
        font-size: 16px;
      }
    }
  }
}
</style>
