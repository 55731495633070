<template>
  <div>
    <div class="date-pickers-container unselectable">
      <date-drop-down
        period="month"
        placeHolder="شهر"
        key="asdf"
        expanded
        v-model.number="dateOptions.month"
        @input="onDateSelected('month')"
        :class="{ selected: dateFilter === 'month' }"
      ></date-drop-down>
      <date-drop-down
        period="year"
        placeHolder="سنة"
        key="qerqwe"
        expanded
        v-model.number="dateOptions.year"
        @input="onDateSelected('year')"
        :class="{ selected: dateFilter === 'year' }"
      ></date-drop-down>
      <date-drop-down
        period="day"
        placeHolder="يوم"
        key="2grwg"
        expanded
        v-model="dateOptions.day"
        @input="onDateSelected('day')"
        :class="{ selected: dateFilter === 'day' }"
      ></date-drop-down>
    </div>

    <div
      class="no-graph unselectable"
      style="position: relative"
      v-if="series[0].data.length === 0"
    >
      <loading-spinner position="absolute" :size="60" v-if="loading"></loading-spinner>
      <p v-else>لا يوجد بيانات</p>
    </div>

    <div v-else class="graph-container">
      <apexchart type="area" height="350" :options="chartOptions" :series="series" />
    </div>

    <!-- <loadingComponent position="absolute" v-if="loading"></loadingComponent> -->
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment'
import dropdownMenu from '../../input/DropDownMenu'
import dateDropDown from '../../input/DateDropDown'
import getDataPoints from './shopInsights'
export default {
  props: {
    elementId: {
      required: true,
      default: 'not-set',
    },
    insightsSection: String
  },
  components: {
    dropdownMenu,
    dateDropDown
  },
  data () {
    return {
      dateOptions: {
        day: null,
        month: null,
        year: null
      },
      showDatePicker: false,
      series: [{
        data: []
      }],
      chartOptions: {
      },
      dateFilter: null,
      loading: false
    }
  },
  methods: {
    onDateSelected (payload) {
      this.dateFilter = payload
      switch (this.dateFilter) {
        case 'day':
          this.dateOptions.month = null
          this.dateOptions.year = null
          break
        case 'month':
          this.dateOptions.day = null
          this.dateOptions.year = null
          break
        case 'year':
          this.dateOptions.day = null
          this.dateOptions.month = null
          break
      }
    },
    requestReads () {
      let chartOptions = {
        xaxis: {
          type: 'datetime'
        },
        stroke: {
          curve: 'straight'
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow'
        },
        tooltip: {
          x: {
            format: 'd MM yyyy'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        }
      }
      if (this.elementId && this.dateFilter) {
        this.loading = true
        this.$api.customRequest({
          method: 'get',
          url: `/services/chatbot/pages/${this.$store.getters['page/id']}/users/${this.$store.getters['user/id']}/insights/${this.insightsSection}/${this.elementId}`,
          
          responseType: 'json',
          params: {
            by: this.dateFilter,
            value: this.dateOptions[this.dateFilter]
          }
        })
          .then(({ data }) => {
            if (data.success && data.reads) {
              let { dataPoints, chartOptions } = getDataPoints(data.reads, this.dateFilter, Object.values(this.dateOptions).filter(val => val != null)[0])
              this.series[0].data = dataPoints
              this.chartOptions = chartOptions
            } else {
              this.series[0].data = []
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  watch: {
    dateFilter (newVal, oldVal) {
      if (newVal && this.elementId != null) {
        this.requestReads()
      }
    },
    elementId (newVal, oldVal) {
      if (newVal && this.dateFilter != null) {
        this.requestReads()
      }
    },
    dateOptions: {
      deep: true,
      handler: function (newVal) {
        this.requestReads()
      }
    }
  },
  created () {
    this.requestReads()
  }
}
</script>

<style scoped lang="scss">
  .date-pickers-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 50px 0px;
    position: relative;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    &>div {
      flex: 1;
      border: none;
      // position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 50%;
        width: 1px;
        background: rgb(224, 224, 224);
        top: 25%;
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        &::after {
          content: '';
          display: none;
        }
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .graph-container {
    border-radius: 6px;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding: 10px;
  }

  .no-graph {
    height: 100px;
    width: 100%;
    border: 2px dashed #007bfe;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    border-radius: 6px;
    color: #a5a3a3;
    font-weight: bold;
    font-size: 20px;
  }

  .m-dropdown-menu {
    &.selected {
      background: dodgerblue;
      border: none;
      color: white;
    }
  }

  @media screen and (max-width: 310px) {
    .date-triggers {
      &>div {
        margin: 0px !important;
        margin-bottom: 10px !important;
      }
    }
  }
</style>
